import { Dropdown } from "@mui/joy";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import MenuButton from "@mui/joy/MenuButton";
import Divider from "@mui/joy/Divider";
import { MoreHorizRounded } from "@mui/icons-material";
import IconButton from '@mui/joy/IconButton';

import { Termin } from '../type'
import { useUserStore } from "../store/userStore";
import { useIndividualTerminStore } from "../store/terminsStore";

function collapseOut(termin_id: string) {
  const element = document.getElementById(termin_id);
  element?.classList.remove("collapseIn")
  element?.classList.add("collapseOut")
}

export default function RowMenu(props: { termin: Termin, setOpenModal: React.Dispatch<boolean> }) {
    const loggedIn = useUserStore((state) => state.loggedIn)
    const setIndividualTermin = useIndividualTerminStore((state) => state.setTermin)
    const setEditing = useIndividualTerminStore((state) => state.setEditing)
    const deleteTermin = useIndividualTerminStore((state) => state.deleteTermin)

        return (
            <>
                <Dropdown>
                    <MenuButton
                      onFocus={() => setIndividualTermin(props.termin)}
                      slots={{ root: IconButton}}
                        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}>
                        <MoreHorizRounded />
                    </MenuButton>
                    <Menu size="sm" sx={{ minWidth: 140 }}>
                        <MenuItem
                            disabled={!loggedIn}
                            onClick={() => {
                              setEditing(true)
                              props.setOpenModal(true)
                            }}
                        >Edit</MenuItem>
                        <Divider />
                        <MenuItem
                          color="danger"
                          disabled={!loggedIn}
                          onClick={() => {
                              deleteTermin()
                              collapseOut(props.termin.id.toString())
                          }}
                          >Delete</MenuItem>
                    </Menu>
                </Dropdown>

            </>
        );
    }


