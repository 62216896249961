import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Sheet,
  Table,
  Typography,
} from "@mui/joy";
import { Box } from "@mui/system";
import { animate, AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Participant, useParticipationStore } from "../store/participations";
import { useTerminsStore } from "../store/terminsStore";
import {
  animate_future,
  exit_fadeOut,
  exit_shrink,
  initial_fadeIn,
  initial_grow,
  transition_fast,
  transition_slow,
} from "../utils/animations";
import { Add, Delete, InfoOutlined } from "@mui/icons-material";
import ParticipantModal from "./modals/ParticipantModal";

export default function ParticipantsTable() {
  // Project
  const project_id = useTerminsStore((state) => state.project_id);

  // Participants
  const fetchParticipations = useParticipationStore(
    (state) => state.fetchParticipations,
  );
  const participants = useParticipationStore((state) => state.participants);
  const loadingParticipants = useParticipationStore((state) => state.loading);
  const deleteParticipation = useParticipationStore(
    (state) => state.deleteParticipation,
  );

  // States
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  // fetch praticipant when project changes
  React.useEffect(() => {
    fetchParticipations();
  }, [project_id]);

  // when editing we want to switch to datagrind

  // if the appearance of table entries should be animated (is false when applying filters)
  const [animate, setAnimate] = React.useState<boolean>(true);

  return (
    <>
      <Sheet
        sx={{
          backgroundColor: "white",
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          maxHeight: { sm: "50vh", md: "60vh", lg: "70vh" },
        }}
      >
        <Table
          variant="outlined"
          sx={(theme) => ({
            mt: 2,
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
            boxShadow: theme.shadow.sm,
            transform: "translateY(0px)",
            "--joy-shadowRing": "0 0 #000",
          })}
        >
          <thead>
            <tr>
              <th style={{ width: 90, padding: "12px 12px" }}>
                Elektronski naslovi udeležencev
              </th>
            </tr>
          </thead>
          <tbody>
            {participants.map((participant: Participant) => {
              return (
                <motion.tr
                  key={participant.id}
                  layout={animate}
                  initial={animate ? initial_grow : initial_fadeIn}
                  animate={animate_future}
                  exit={animate ? exit_shrink : exit_fadeOut}
                  transition={animate ? transition_slow : transition_fast}
                >
                  <td
                    style={{
                      padding: "12px 12px",
                      flex: "1",
                    }}
                  >
                    {participant.email}
                    <IconButton
                      onClick={() => {
                        deleteParticipation(participant.id);
                      }}
                      sx={{ alignSelf: "right" }}
                    >
                      <Delete />
                    </IconButton>
                  </td>
                </motion.tr>
              );
            })}
            {participants.length === 0 && !loadingParticipants ? (
              <tr>
                <motion.td
                  layout={animate}
                  initial={animate ? initial_grow : initial_fadeIn}
                  animate={animate_future}
                  exit={animate ? exit_shrink : exit_fadeOut}
                  transition={animate ? transition_slow : transition_fast}
                  style={{ padding: "12px 12px" }}
                >
                  Dodan ni bil še noben udeleženec
                </motion.td>
              </tr>
            ) : (
              <></>
            )}
            {loadingParticipants ? (
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0.5 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    delay: 0.2,
                    duration: 1,
                  }}
                >
                  <LinearProgress
                    sx={{
                      "--LinearProgress-thickness": "3px",
                    }}
                  />
                </motion.div>
              </AnimatePresence>
            ) : (
              <Box></Box>
            )}
          </tbody>
        </Table>
        <Grid
          container
          spacing={2}
          sx={{
            flexGrow: "1",
          }}
        >
          <Grid
            sx={{
              m: 1.5,
              flexGrow: 1,
            }}
          >
            <Typography level="body-sm" startDecorator=<InfoOutlined />>
              Udeleženci bodo po elektronski pošti sproti obveščeni o spremembah
              urnika.
            </Typography>
          </Grid>
          <Grid
            sx={{
              flexGrow: "1",
              justifySelf: "right",
              m: 1.5,
            }}
          >
            <Box sx={{ justifyContent: "right" }}>
              <Button
                //disabled={disableActions}
                color="neutral"
                startDecorator={<Add />}
                size="sm"
                onClick={() => {
                  setOpenModal(true);
                }}
                sx={{
                  display: "flex",
                  justifySelf: "right",
                }}
              >
                Dodaj udeleženca
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Sheet>
      <ParticipantModal open={openModal} setOpen={setOpenModal} />
    </>
  );
}
