import { match } from "assert";
import { warn } from "console";
import dayjs, { Dayjs } from "dayjs";

export interface Termin {
  id: number;
  project: number;
  termin_type: string;
  date: string;
  start: string;
  end: string;
  location: string;
  startDayjs: Dayjs | undefined;
  endDayjs: Dayjs | undefined;
  terminTypeFull: String;
}

export function createTermin(
  id: number,
  project: number,
  termin_type: string,
  date: string,
  start: string,
  end: string,
  location: string,
): Termin {
  const startDayjs = dayjs(date, "DD.MM.YYYY", true)
    .hour(+start.split(":")[0])
    .minute(+start.split(":")[1]);
  const endDayjs = dayjs(date, "DD.MM.YYYY", true)
    .hour(+end.split(":")[0])
    .minute(+end.split(":")[1]);
  const terminType =
    termin_type === "V"
      ? "Vaja"
      : termin_type === "N"
        ? "Nastop"
        : termin_type === "K"
          ? "Koncert"
          : "Termin";
  return {
    id: id,
    project: project,
    termin_type: termin_type,
    date: date,
    start: start,
    end: end,
    location: location,
    startDayjs: startDayjs,
    endDayjs: endDayjs,
    terminTypeFull: terminType,
  };
}

function getStart(termin: Termin): Date {
  let start = new Date();
  const date = termin.date.split(".");
  start.setFullYear(Number(date[2]), Number(date[1]) - 1, Number(date[0]));
  const time = termin.start.split(":");
  start.setHours(Number(time[0]), Number(time[1]));
  return start;
}

// a function that sorts termins by date and time
export function sortTermins(termins: Termin[], descending: boolean = false) {
  const terminsWithDayJs = termins.map((termin) => {
    return createTermin(
      termin.id,
      termin.project,
      termin.termin_type,
      termin.date,
      termin.start,
      termin.end,
      termin.location,
    );
  });

  let i = 0;
  descending ? (i = -1) : (i = 1);
  terminsWithDayJs.sort((a, b) => {
    let a_date = getStart(a);
    let b_date = getStart(b);
    return a_date.getTime() - b_date.getTime();
  });
  if (descending) {
    terminsWithDayJs.sort((a, b) => {
      let a_date = getStart(a);
      let b_date = getStart(b);
      return (a_date.getDate() - b_date.getDate()) * i;
    });
  }
  return terminsWithDayJs;
}

export interface Project {
  id: number;
  name: string;
  owner: number;
  end_date: string;
  public_view: boolean;
  // description: string;
  // termins: Termin[];
}

export function createProject(
  id: number,
  name: string,
  owner: number,
  end_date: string,
  public_view: boolean,
): Project {
  return {
    id: id,
    name: name,
    owner: owner,
    end_date: end_date,
    public_view: public_view,
  };
}

export function sortProjects(projects: Project[]) {
  projects.sort((a, b) => {
    let a_date = new Date(a.end_date);
    let b_date = new Date(b.end_date);
    return a_date.getTime() - b_date.getTime();
  });
  return projects;
}

const weekdaysInSlovenian = [
  "Ponedeljek",
  "Torek",
  "Sreda",
  "Četrtek",
  "Petek",
  "Sobota",
  "Nedelja",
];

export function getSlovenianNameOfDay(day: Dayjs): string {
  return weekdaysInSlovenian[day.day() - 1];
}
